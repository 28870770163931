.flag-icon-ex1 {
    background-image: url(images/ex1.svg);
}

.flag-icon-ex1.flag-icon-squared {
    background-image: url(images/ex1.svg);
}

.flag-icon-ex2 {
    background-image: url(images/ex2.svg);
}

.flag-icon-ex2.flag-icon-squared {
    background-image: url(images/ex2.svg);
}

.flag-icon-ex3 {
    background-image: url(images/ex3.svg);
}

.flag-icon-ex3.flag-icon-squared {
    background-image: url(images/ex3.svg);
}