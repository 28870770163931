:root {
    --main-font: 'Public Sans';
    --second-font: 'Paytone One';
    --thirth-font: 'Roboto';
    --forth-font: 'Montserrat';
    --color-text: rgb(42, 53, 71);
    --primary-color: #d4732b;
    --primary-color-light: rgba(212, 115, 43, 0.2);
    --primary-table-color: #d4732b;
    --primary-table-hover-color: #a65823;
    --border-color: #f7922b;
    --link-color: #1890ff;
    --success-color: #52c41a;
    --warning-color: #faad14;
    --error-color: #f5222d;
    --font-size-base: 14px;
    --heading-color: rgba(0, 0, 0, 0.85);
    --text-color: rgba(0, 0, 0, 0.65);
    --text-color-secondary: rgba(0, 0, 0, 0.45);
    --disabled-color: rgba(0, 0, 0, 0.25);
    --border-radius-base: 2px;
    --border-color-base: #d9d9d9;
    --box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.ant-layout-header {
    height: 100%;
    line-height: 100%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    background-color: #FFFFFF;
    color: var(--color-text);
}

.main_content {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
}

@media (min-width: 1200px) {
    .main_content {
        max-width: 1200px;
    }
}


p {
    margin: 0;
    padding: 0;
}

a {
    color: black;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

.ant-layout-header, .ant-layout-content, .ant-layout-footer {
    padding: 0;
}

.ant-layout, .ant-layout-footer {
    background-color: transparent;
}

.ant-layout-header, .ant-menu {
    background: transparent;
}

.ant-menu:not(.ant-menu-horizontal) {
    background: transparent;
}

.ant-select .ant-select-lg > .ant-input .ant-input-lg{
    line-height: 90px !important;
}

.user-action-btn {
    color: white;
    padding: 0 5px;
    margin: 0 10px;
}

.user-action-btn--edit {
    background-color: #1890ff;
}

.user-action-btn--delete {
    background-color: #f5222d;
}

.map {
    width: 100%;
    display: flex;
    gap: 10px
}

.MuiPaper-root .MuiCardContent-root:last-child {
    padding-bottom: 10px;
}

.burger-btn {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
}

.burger-btn:before {
    content: '';
    position: absolute;
    top: 0;
    width: 30px;
    height: 2px;
    background-color: black;
}

.burger-btn:after {
    content: '';
    position: absolute;
    top: 0;
    width: 30px;
    height: 2px;
    background-color: black;
}

.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 50px;
    right: 0;
}

/*---------------------ant-dropdown-menu--------------------------------*/
.ant-dropdown-menu .ant-dropdown-menu-item:hover {
    font-weight: bolder;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon{
    font-size: 18px !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 10px 20px !important;
}
/*-----------------------------------------------------*/

.base-Popper-root.css-1h5nd1a-MuiPopper-root-ColorPickerPopper-root{
    z-index: 1001;
}